<template>
  <div class="brandDetails">
    <header-nav></header-nav>
    <div class="brandDetails-top">
      <b-container class="brandDetails-top-cont" fluid>
        <b-row>
          <b-col class="brandDetails-top-left" sm="6" md="4" lg="4" xl="3">
            <img
              :src="brandInfo.brand_logo"
              :alt="brandInfo.brand_name"
            />
          </b-col>
          <b-col class="brandDetails-top-right" sm="6" md="4" lg="4" xl="9">
            <b-row class="right-details">
              <b-col class="collection" sm="6" md="4" lg="4" xl="4">
                <span class="collection-txt">{{brandInfo.brand_name}}</span>
                <div class="collection-img" @click="brandCollection">
                  <span>收藏</span>
                  <img src="@/../public/icon/mall/brandDetails/aixin.png" alt="">
                </div>
              </b-col>
              <b-col class="right-details-txt" sm="6" md="4" offset-lg="2" lg="6" offset-xl="2" xl="6">
                {{brandInfo.brand_introduce}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="brandDetails-mid">
      <b-container class="brandDetails-mid-cont" fluid>
          <b-row class="brandDetails-mid-in">
              <b-col @click="titleTrueId = item.id" :class="titleTrueId == item.id?'title-active':'title-item'" v-for="(item,index) in titleList" :key="index" sm="6" md="4" lg="4" xl="1">
                  {{item.name}}
              </b-col>
          </b-row>
      </b-container>
    </div>
    <b-container v-if="titleTrueId=='1'" class="brandDetails-bottom" fluid> 
      <b-row class="category">
        <b-col class="category-title" sm="6" md="4" lg="4" xl="1">
          分类
        </b-col>
        <b-col class="category-line" sm="6" md="4" lg="4" xl="1">
          <div>

          </div>
        </b-col>
        <b-col :class="label_id==item.label_id?'category-active':'category-item'" sm="6" md="4" lg="4" xl="1" v-for="(item,index) in categoryList" :key="index" @click="chooseCategory(item.label_id)">
          {{item.label_name}}
        </b-col>
      </b-row>
      <b-row class="screening">
        <b-col class="screening-title" sm="6" md="4" lg="4" xl="1">
          分类
        </b-col>
        <b-col class="screening-line" sm="6" md="4" lg="4" xl="1">
          <div>
            
          </div>
        </b-col>
        <b-col sm="6" md="4" lg="4" xl="1" v-for="(item,index) in screeningList" :key="index" @click="chooseSort(item)"
            :class="sort_type==item.id?'screening-active':'screening-item'">
          {{item.name}}
        </b-col>
        <b-col class="screening-price" sm="12" md="2" lg="2" xl="1" @click="chooseSort({id:3})">
            <span :class="sort_type==3||sort_type==4?'price-txt-chd':'price-txt'">价格</span>
            <div class="price-symbol">
              <span :class="sort_type==3&&priceTrue?'price-up-chd':'price-up'"></span>
              <span :class="sort_type==3&&priceTrue?'price-down-chd':'price-down'"></span>
            </div>
        </b-col>
      </b-row>
      <b-row class="goods">
                <b-col class="goods-item" v-for="(item,index) in goodsList" :key="index" sm="12"
            md="6"
            lg="6"
            xl="3">
                    <div class="goods-in" @click="toProductDetail(item)">
                      <div class="pic-wrap">
                        <img :src="item.file_path" alt="">
                      </div>
                      <span class="goods-name">{{item.goods_name}}</span>
                      <span class="goods-price">￥{{item.sale_price}}</span>
                      <div class="goods-unit">
                          <span class="sale-txt">已售</span>
                          <span class="sale-num">{{item.sale_count}}</span>
                          <span class="sale-txt">件</span>
                      </div>
                    </div>
                </b-col>
            </b-row>
            <el-pagination
        v-if="totalPage > 0"
        :page-count="totalPage"
        @current-change="changeCurrentPage"
        class="margin-top-80"
        background
        layout="prev, pager, next"
      ></el-pagination>
    </b-container>
    <b-container v-if="titleTrueId=='2'" class="brandDetails-bottom" fluid> 
      品牌介绍
    </b-container>
    <b-container v-if="titleTrueId=='3'" class="brandDetails-bottom" fluid> 
      品牌视频
    </b-container>
    <b-container v-if="titleTrueId=='4'" class="brandDetails-bottom" fluid> 
      商品资料
    </b-container>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>
<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
import dump from "@/util/dump";
export default {
  components: {
    headerNav,
    Right,
    footerNav
  },
  data() {
    return {
        goodsList:[],//产品数据
        titleList:[{name:'商品列表',id:'1'},{name:'品牌介绍',id:'2'},{name:'品牌视频',id:'3'},{name:'商品资料',id:'4'}],
        categoryList:[{name:'不限',id:''},{name:'壁灯',id:'50'},{name:'吊灯',id:'118'},{name:'吸顶灯',id:'119'}],//分类筛选
        screeningList: [{name:'综合',id:''}, {name:"人气",id:'5'}, {name:"销量",id:'1'}, {name:"新品",id:'2'}],//筛选数据
        sort_type:'',//筛选id
        currentPage:1,//当前页码
        priceTrue:false,//当前是否为价格筛选
        totalPage:0,//总页数
        label_id:'',
        titleTrueId:'1',
        brandId: "",
        brandType: "",
        token: null,
        brandInfo: {}
    };
  },
  created(){
    this.token = localStorage.getItem("token");
    this.brandId = this.$route.query.brand_id;
    this.brandType = this.$route.query.brand_type;
    this.getData();
    this.getBrandInfo();
  },
  methods:{
    // 获取品牌信息
    getBrandInfo(){
      this.$api.getbrandinfo({
        brand_type: this.brandType,
        brand_id: this.brandId,
        token: this.token
      }).then(res => {
        if(res.status == 100){
          this.brandInfo = res.data.brand;
          this.categoryList = res.data.types;
        }
      })
    },
    // 获取产品列表
    getData(){
      const that = this
      let para = {
        style_type: that.style_type,
				brand_id: this.brandId,
				goods_type: this.brandType,
				sort_type: that.sort_type,
				label_id: that.label_id,
				currentPage: that.currentPage,
        shopId: this.cookie.getCookie("shopId")
      }
      that.$api.getbrandgoods(para).then((res)=>{
        if(res.status == 100) {
          that.goodsList = res.data.goods
          that.totalPage = res.data.totalPage
        }
      })
    },
    chooseCategory(id){
      const that = this
      that.label_id = id
      that.getData()
    },
    //筛选
    chooseSort(item){
      const that = this
      if(item.id == 3){
        that.priceTrue = true
        if(that.sort_type == item.id){
          that.sort_type = 4
        }else{
          that.sort_type = 3
        }
      }else{
        that.priceTrue = false
        that.sort_type = item.id
      }
      that.currentPage = 1
      that.getData()
    },
    //切换页码
    changeCurrentPage(info) {
      this.currentPage = info;
      this.getData();
      this.$utils.scrollTop();
    },
    //收藏品牌
    brandCollection(){
      const that = this
      let para = {
        collection_type: 2,
				project_id: that.$route.query.brand_id,
				brand_type: that.$route.query.brand_type,
      }
      that.$api.brandCollection(para).then((res)=>{
        if(res.status == 100){
          that.$message({
            message: "收藏成功！",
            type: "success",
          });
        }else if(res.status == 103){
          dump.link({
            type: 1,
            link: "/login",
          });
        }
      })
    },
    // 跳转到商品详情页
    toProductDetail(obj){
      dump.link({
        type: 3,
        link: "/product/details",
        params: {
          good_id: obj.goods_id
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/view/mall/brandDetails/brandDetails";
</style>